import React, { useState } from 'react'
import styled from '@emotion/styled'

const Wrapper = styled('div')`
  margin: 1rem 0;
`

const Button = styled('button')`
  padding: 1rem 2rem;
`

const Count = styled('span')`
  padding: 1rem 2rem;
`

export const Counter = () => {
	const [val, setVal] = useState(30)
	return (
		<Wrapper>
			A: <input step="10" type="range" min="0" max="70" onChange={(e) => setVal(e.target.value)} value={val} /> {val} <br />
			B: <input step="0.5" type="range" min="0" max="70" onChange={(e) => setVal(70 - e.target.value)} value={70 - val}/> {70 - val}
		</Wrapper>
	)
}
