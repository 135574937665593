// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-another-page-tsx": () => import("./../../../src/pages/another-page.tsx" /* webpackChunkName: "component---src-pages-another-page-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-front-template-tsx": () => import("./../../../src/templates/FrontTemplate.tsx" /* webpackChunkName: "component---src-templates-front-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/IndexTemplate.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-single-template-tsx": () => import("./../../../src/templates/SingleTemplate.tsx" /* webpackChunkName: "component---src-templates-single-template-tsx" */)
}

